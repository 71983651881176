import { render, staticRenderFns } from "./information-modal.vue?vue&type=template&id=0f429552&scoped=true&"
import script from "./information-modal.vue?vue&type=script&lang=js&"
export * from "./information-modal.vue?vue&type=script&lang=js&"
import style0 from "./information-modal.vue?vue&type=style&index=0&id=0f429552&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f429552",
  null
  
)

export default component.exports