<template>
  <CModalExtended
    :title="modalTitle"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <CRow class="options">
      <CCol sm="4">
        <table class="info-table">
          <thead>
            <tr>
              <th>
                CAPACIDAD TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TOTAL TEUS:{{ this.hasInfo ? bay.TotalJson.TotalJson[0].TotalTeus:'' }}</td>
            </tr>
            <tr>
              <td>TOTAL CONT 20 FT:{{ this.hasInfo ? bay.TotalJson.TotalJson[0].TotalCont20ft:'' }}</td>
            </tr>
            <tr>
              <td>TOTAL CONT 40 FT:{{ this.hasInfo ? bay.TotalJson.TotalJson[0].TotalCont40ft:'' }}</td>
            </tr>
            <tr>
              <td>REEFER PLUG:{{ this.hasInfo ? bay.TotalJson.TotalJson[0].ReeferPlug:'' }}</td>
            </tr>
          </tbody>
        </table>
      </CCol>
      <CCol sm="4">
        <table class="info-table">
          <thead>
            <tr>
              <th>
                CAPACIDAD OCUPADA
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TOTAL TEUS:{{ this.hasInfo ? bay.OccupiedJson.OccupiedJson[0].TotalTeus:'' }}</td>
            </tr>
            <tr>
              <td>TOTAL CONT 20 FT:{{ this.hasInfo ? bay.OccupiedJson.OccupiedJson[0].TotalCont20ft:'' }}</td>
            </tr>
            <tr>
              <td>TOTAL CONT 40 FT:{{ this.hasInfo ? bay.OccupiedJson.OccupiedJson[0].TotalCont40ft:'' }}</td>
            </tr>
            <tr>
              <td>REEFER PLUG:{{ this.hasInfo ? bay.OccupiedJson.OccupiedJson[0].ReeferPlug:'' }}</td>
            </tr>
          </tbody>
        </table>
      </CCol>
      <CCol sm="4">
        <table class="info-table">
          <thead>
            <tr>
              <th>
                CAPACIDAD DISPONIBLE
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TOTAL TEUS:{{ this.hasInfo ? bay.AvailableJson.AvailableJson[0].TotalTeus:'' }}</td>
            </tr>
            <tr>
              <td>TOTAL CONT 20 FT:{{ this.hasInfo ? bay.AvailableJson.AvailableJson[0].TotalCont20ft:'' }}</td>
            </tr>
            <tr>
              <td>TOTAL CONT 40 FT:{{ this.hasInfo ? bay.AvailableJson.AvailableJson[0].TotalCont40ft:'' }}</td>
            </tr>
            <tr>
              <td>REEFER PLUG:{{ this.hasInfo ? bay.AvailableJson.AvailableJson[0].ReeferPlug:'' }}</td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x" />
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { mapState } from "vuex";
import ENUM from '@/_store/enum';

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    bay: null,
  };
}

//Methods
function toggle(newVal) {
  if(newVal && this.vesselBayId)
    this.getBayInfo();
  else
    this.bay = null;

  this.modalActive = newVal;
}
function getBayInfo() {
  if(this.visit)
    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  else
    this.$store.state.planificacionestiba.apiStateForm = 1;

  let ruta = this.visit ? 'VisitVesselBayInformation' : 'StowagePlanningVesselBayInformation';
  let params = this.visit ? {
    VisitId: this.VisitId,
    VisitEdiFileId: this.EdiFileId,
    VesselBayId: this.vesselBayId,
  }: {
    StowagePlanningId: this.planificacionId,
    EdiFileId: this.ediFileId,
    VesselBayId: this.vesselBayId,
  };

  this.$http
    .ejecutar("GET", ruta, params)
    .then((response) => {
      this.bay = response.data.data[0];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if(this.visit)
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      else
        this.$store.state.planificacionestiba.apiStateForm = 0;
    });
}

//Computed
function hasInfo() {
  return this.bay != null;
}
function modalTitle() {
  return `BAY INFORMATION: ${this.bayCode}`
}

export default {
  name: "bay-information-modal",
  mixins: [],
  props: {
    modal: Boolean,
    vesselBayId: {
      type: String,
      default: '',
    },
    bayCode: {
      type: String,
      default: '',
    },
    visit: {
      type: Boolean,
      default: false,
    }
  },
  data,
  methods: {
    toggle,
    getBayInfo,
  },
  computed: {
    modalTitle,
    hasInfo,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      ediFileId: (state) => state.planificacionestiba.EdiFileId,
      VisitId: state => state.visitas.VisitId,
      EdiFileId: state => state.visitas.EdiFileId,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>
.options {
  min-height: 210px;
  overflow-y: auto;
  scrollbar-width: thin;
}

/* Info Tables */
.info-table {
  width: 100%;
  border-spacing: 0;
  border: 2px solid #696969;
  border-radius: 0.5em;
}
.info-table thead tr th {
/*   background-color: #616161; */
  background-color: #e0e0e0;
  color: #434343;
  border-bottom: 2px solid #696969;
} 
.info-table th,
.info-table td{
  width: 100%;
  padding: 10px;
}
.info-table th {
  text-align: left;
}
.info-table tr td{  
  background-color: #e0e0e0;
  font-size: small;
}
</style>